import { useEffect, useState } from 'react';
import { handleErrors } from 'functions/Errors';
import { loadingHandler } from 'functions/data';
import { apiUrls, apiWrapper } from 'config/api';
// Кастномный хук для запроса данных
export default function useFetch(url, method, options) {
    const [data, setData] = useState(null);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    useEffect(() => { loadingHandler(fetch, setLoading); }, [url, options?.page]);
    // Запрос данных, обработка ошибок
    const fetch = async () => {
        const response = await apiWrapper[method](url, options);
        const { ok, data: responseData } = response;
        if (ok)
            setData(responseData);
        else
            setError(handleErrors(response));
        setIsDataFetched(true);
    };
    const refetch = () => loadingHandler(fetch, setLoading);
    return {
        data, error, loading, refetch, isDataFetched
    };
}
export async function getAppConfig(authData) {
    apiWrapper.addRequestTransform((request) => {
        request.headers = { ...request.headers, ...authData };
    });
    const response = await apiWrapper.get(apiUrls.appConfig.show);
    if (response.ok) {
        const data = response.data;
        return {
            ok: true,
            data
        };
    }
    return {
        ok: false,
        error: handleErrors(response)
    };
}
