import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { useParams } from 'react-router-dom';
import ServicesDashboardAvailableItems from 'helpers/Services/Dashboard/AvailableItems';
import ServicesDashboardChangeButtons from 'helpers/Services/Dashboard/ChangeButtons';
import ServicesDashboardItemsInCategory from 'helpers/Services/Dashboard/ItemsInCategory';
import InputSearch from 'helpers/Input/Search';
import { handleErrors, useShowError } from 'functions/Errors';
import { isSomeSelected } from 'functions/services';
import useFetch from 'functions/Api';
import { apiUrls, apiWrapper } from 'config/api';
import { loadingHandler } from 'functions/data';
import ServicesDashboardWrapper from 'helpers/Services/Dashboard/Wrapper';
// Таб с докторами на странице настройки услуг
const SettingsServicesTabsDoctors = () => {
    const { categoryId } = useParams();
    const [searchValue, setSearchValue] = useQueryParam('doctorName', StringParam);
    const [availableDoctors, setAvailableDoctors] = useState([]);
    const [doctorsInCategory, setDoctorsInCategory] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const setError = useShowError();
    const { data: availableDoctorsData, error: availableDoctorsError, refetch: refetchAvailableDoctors, loading: isAvailableDoctorsLoading } = useFetch(apiUrls.doctors.list, 'get', { service_category_id: categoryId, full_name: searchValue });
    const { data: doctorsInCategoryData, error: doctorsInCategoryError, refetch: refetchDoctorsInCategory, loading: isDoctorsInCategoryLoading } = useFetch(apiUrls.serviceCategories.doctors(categoryId), 'get');
    useEffect(() => {
        const error = availableDoctorsError || doctorsInCategoryError;
        if (error)
            setError(error);
    }, [availableDoctorsError, doctorsInCategoryError]);
    useEffect(() => {
        setIsLoading(isAvailableDoctorsLoading || isDoctorsInCategoryLoading);
    }, [isAvailableDoctorsLoading, isDoctorsInCategoryLoading]);
    useEffect(() => {
        if (availableDoctorsData) {
            setAvailableDoctors(availableDoctorsData.doctors.map(doctor => ({ ...doctor, name: doctor.full_name })));
        }
    }, [availableDoctorsData]);
    useEffect(() => {
        if (doctorsInCategoryData) {
            setDoctorsInCategory(doctorsInCategoryData.service_category.doctors.map(doctor => ({ ...doctor, name: doctor.full_name })));
        }
    }, [doctorsInCategoryData]);
    // Если в поле поиска активно, запросить найденые сервисы
    useEffect(() => {
        refetchAvailableDoctors();
    }, [searchValue]);
    // Добавление/удаление врачей из категории
    const changeDoctorsCategory = async (action) => {
        const servicesIds = (action === 'add' ? availableDoctors : doctorsInCategory).reduce((acc, { id: serviceId, isChecked }) => {
            if (isChecked)
                acc.push(serviceId);
            return acc;
        }, []);
        const formData = {
            ids: servicesIds,
            new_category_id: categoryId
        };
        const response = await apiWrapper.patch(apiUrls.doctors.changeServiceCategory, formData);
        if (response.ok) {
            refetchAvailableDoctors();
            refetchDoctorsInCategory();
        }
        else
            setError(handleErrors(response));
    };
    // Вывод кнопок смены категории
    const renderChangeButtons = () => {
        const addButtonParams = {
            onClick: () => loadingHandler(() => changeDoctorsCategory('add'), setIsLoading),
            isDisabled: !isSomeSelected(availableDoctors)
        };
        const removeButtonPros = {
            onClick: () => changeDoctorsCategory('remove'),
            isDisabled: !isSomeSelected(doctorsInCategory)
        };
        return (React.createElement(ServicesDashboardChangeButtons, { addButton: addButtonParams, removeButton: removeButtonPros }));
    };
    // Вывод инпута для поиска врачей
    const renderSearchInput = () => (React.createElement(InputSearch, { value: searchValue, onChangeSearch: setSearchValue, testId: 'ServicesSearch', wrapperClassName: 'mt-4' }));
    const renderContent = () => (React.createElement(ServicesDashboardWrapper, { isLoading: isLoading, className: 'p-8' }, (doctorsInCategoryData && availableDoctorsData) && (React.createElement(React.Fragment, null,
        React.createElement(ServicesDashboardAvailableItems, { availableItems: availableDoctors, setAvailableItems: setAvailableDoctors, title: '\u0421\u043F\u0438\u0441\u043E\u043A \u0432\u0440\u0430\u0447\u0435\u0439', renderButtons: renderChangeButtons, renderSearchInput: renderSearchInput, placeholder: '\u041D\u0435\u0442 \u0441\u0432\u043E\u0431\u043E\u0434\u043D\u044B\u0445 \u0432\u0440\u0430\u0447\u0435\u0439', kind: 'doctorsInCategoryData' }),
        React.createElement(ServicesDashboardItemsInCategory, { itemsInCategory: doctorsInCategory, setItemsInCategory: setDoctorsInCategory, title: `Врачи в категории «${doctorsInCategoryData.service_category.name}»`, kind: doctorsInCategoryData.service_category.kind, placeholder: '\u041D\u0435\u0442 \u0432\u0440\u0430\u0447\u0435\u0439 \u0432 \u044D\u0442\u043E\u0439 \u043A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u0438' })))));
    return renderContent();
};
export default SettingsServicesTabsDoctors;
