import React from 'react';
import { NavLink } from 'react-router-dom';
import ClinicsSelector from 'components/clinics/Selector';
import Button from 'helpers/Button';
import { IconDashboards, IconExit, IconFeedback, IconMailings, IconPatients, IconSchedules, IconSettings, IconTemplates } from 'svg/menu';
import { IconFile, IconLogo } from 'svg/common';
import { useAvailableSections } from 'functions/data';
import navigationUrls from 'navigation/routes';
import { removeAppConfig } from 'redux/slices/appConfig';
import { removeUser } from 'redux/slices/user';
import { useAppDispatch } from 'redux/hooks';
const mainMenuItems = [
    {
        label: 'Дашборды',
        url: navigationUrls.dashboards.index,
        id: 'dashboards',
        sectionKey: 'dashboards',
        Icon: IconDashboards
    },
    {
        label: 'Рассылки',
        url: navigationUrls.campaigns.index,
        id: 'campaigns',
        sectionKey: 'campaigns',
        Icon: IconMailings,
        alwaysAvailable: true
    },
    {
        label: 'Шаблоны',
        url: navigationUrls.templates.index,
        id: 'templates',
        sectionKey: 'templates',
        Icon: IconTemplates
    },
    {
        label: 'Пациенты',
        url: navigationUrls.patients.index,
        id: 'patients',
        sectionKey: 'patients',
        Icon: IconPatients
    },
    {
        label: 'Подтверждение записи',
        url: navigationUrls.schedules.index,
        id: 'schedules',
        sectionKey: 'schedules',
        Icon: IconSchedules
    },
    {
        label: 'Очереди',
        url: navigationUrls.queues.list,
        id: 'queues',
        sectionKey: 'queues',
        Icon: IconFile
    },
    {
        label: 'Обратная связь',
        url: navigationUrls.feedback.index,
        id: 'feedback',
        sectionKey: 'campaigns',
        Icon: IconFeedback
    }
];
const secondaryMenuItems = [
    {
        label: 'Настройки',
        url: navigationUrls.settings.index,
        id: 'settings',
        Icon: IconSettings
    }
];
// Компонент сайдбара
const Sidebar = () => {
    const availableSections = useAvailableSections();
    const availableMenuItems = availableSections.length ?
        mainMenuItems.filter(item => item.alwaysAvailable || availableSections.includes(item.sectionKey)) :
        mainMenuItems;
    const dispatch = useAppDispatch();
    // Выход из аккаунта
    const logOut = () => {
        dispatch(removeUser());
        dispatch(removeAppConfig());
    };
    // Вывод элемента меню сайдбара
    const renderMenuItem = ({ label, url, id, Icon }) => (React.createElement(NavLink, { className: ({ isActive }) => `sidebar__item ${isActive ? 'sidebar__item_active' : ''} flex items-center h-10`, to: url, key: `menuItem${id}`, "data-testid": `Sidebar_menuItem_${id}` },
        React.createElement(Icon, { className: 'ml-5 mr-3' }),
        React.createElement("span", { className: 'sidebar__item__label text-smaller font-semibold' }, label)));
    const renderContent = () => (React.createElement("div", { className: 'sidebar flex flex-col justify-between bg-white pt-8', "data-testid": 'Sidebar' },
        React.createElement("div", null,
            React.createElement(IconLogo, { className: 'ml-6 mb-8' }),
            React.createElement("div", { className: 'pl-3.5 pr-3.5 mb-5' },
                React.createElement(ClinicsSelector, null)),
            React.createElement("div", null, availableMenuItems.map(item => renderMenuItem(item))),
            secondaryMenuItems.map(item => renderMenuItem(item))),
        React.createElement(Button, { className: 'sidebar__exitButton flex items-center mb-10 pl-6 h-10', onClick: logOut, testId: 'Sidebar_exitButton' },
            React.createElement(IconExit, { className: 'mr-3' }),
            React.createElement("span", { className: 'text-smaller font-semibold' }, "\u0412\u044B\u0439\u0442\u0438"))));
    return renderContent();
};
export default Sidebar;
