import React, { useContext } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { ServicesDashboardContext } from 'helpers/Services/Dashboard/Context';
import ServicesDashboardService from 'helpers/Services/Dashboard/Item';
// Вывод списка услуг в категории
const ServicesDashboardList = ({ placeholder, loadMore = () => { }, totalPages = 1, page = 1 }) => {
    const { services, kind } = useContext(ServicesDashboardContext);
    // Вывод информации если услуг нет или не найдено
    const renderPlaceholder = () => (React.createElement("p", null, placeholder));
    // Вывод услуг
    const renderServices = () => (React.createElement(InfiniteScroll, { pageStart: 1, loadMore: loadMore, hasMore: totalPages > page, className: 'flex flex-col gap-6' }, services.map(item => React.createElement(ServicesDashboardService, { service: item, key: `${kind}${item.id}` }))));
    const renderContent = () => (React.createElement("div", { className: 'bg-white flex flex-1 overflow-auto p-4 mb-2', "data-testid": 'ServicesDashboardList' }, services.length !== 0 ? renderServices() : renderPlaceholder()));
    return renderContent();
};
export default ServicesDashboardList;
