import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { useParams } from 'react-router-dom';
import ServicesDashboardAvailableItems from 'helpers/Services/Dashboard/AvailableItems';
import ServicesDashboardChangeButtons from 'helpers/Services/Dashboard/ChangeButtons';
import ServicesDashboardItemsInCategory from 'helpers/Services/Dashboard/ItemsInCategory';
import InputSearch from 'helpers/Input/Search';
import ServicesDashboardWrapper from 'helpers/Services/Dashboard/Wrapper';
import { handleErrors, useShowError } from 'functions/Errors';
import { isSomeSelected } from 'functions/services';
import { loadingHandler } from 'functions/data';
import useFetch from 'functions/Api';
import { apiUrls, apiWrapper } from 'config/api';
// Таб с услугами на странице настройки услуг
const SettingsServicesTabsServices = () => {
    const { categoryId } = useParams();
    const [searchValue, setSearchValue] = useQueryParam('serviceName', StringParam);
    const [availableServices, setAvailableServices] = useState([]);
    const [servicesInCategory, setServicesInCategory] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const setError = useShowError();
    const { data: availableServicesData, error: availableServicesError, refetch: refetchAvailableServices, loading: isAvailableServicesLoading } = useFetch(apiUrls.serviceCategories.showTreat, 'get', { service_name: searchValue });
    const { data: servicesInCategoryData, error: servicesInCategoryError, refetch: refetchServicesInCategory, loading: isServicesInCategoryLoading } = useFetch(apiUrls.serviceCategories.services(categoryId), 'get');
    useEffect(() => {
        const error = availableServicesError || servicesInCategoryError;
        if (error)
            setError(error);
    }, [availableServicesError, servicesInCategoryError]);
    useEffect(() => {
        setIsLoading(isAvailableServicesLoading || isServicesInCategoryLoading);
    }, [isAvailableServicesLoading, isServicesInCategoryLoading]);
    useEffect(() => {
        if (servicesInCategoryData) {
            setServicesInCategory(servicesInCategoryData.service_category.services);
        }
    }, [servicesInCategoryData]);
    useEffect(() => {
        if (availableServicesData) {
            setAvailableServices(availableServicesData.service_category.services);
        }
    }, [availableServicesData]);
    // Если в поле поиска активно, запросить найденые сервисы
    useEffect(() => {
        refetchAvailableServices();
    }, [searchValue]);
    // Обновление категорий услуг (перенос услуг из одной категории в другую)
    // id - айди текущей категории
    // Если этот параметр пришел, то в эту категорию переносим услуги из справочника
    // Если не пришел - из категории в справочник
    const changeServiceCategory = async (id) => {
        const { id: treatCategoryId } = availableServicesData?.service_category || {};
        const { id: currentCategoryId } = servicesInCategoryData?.service_category || {};
        const parentCategoryId = id ? treatCategoryId : currentCategoryId;
        const newCategoryId = id || treatCategoryId;
        const servicesIds = (id ? availableServices : servicesInCategory).reduce((acc, { id: serviceId, isChecked }) => {
            if (isChecked)
                acc.push(serviceId);
            return acc;
        }, []);
        const formdata = {
            ids: servicesIds,
            new_category_id: newCategoryId
        };
        const response = await apiWrapper.patch(apiUrls.serviceCategories.changeServiceCategory(parentCategoryId), formdata);
        if (response.ok) {
            refetchAvailableServices();
            refetchServicesInCategory();
        }
        else
            setError(handleErrors(response));
    };
    // Вывод кнопок смены категории
    const renderChangeButtons = () => {
        const addButtonParams = {
            onClick: () => loadingHandler(() => changeServiceCategory(categoryId), setIsLoading),
            isDisabled: !isSomeSelected(availableServices)
        };
        const removeButtonPros = {
            onClick: () => loadingHandler(changeServiceCategory, setIsLoading),
            isDisabled: !isSomeSelected(servicesInCategory)
        };
        return (React.createElement(ServicesDashboardChangeButtons, { addButton: addButtonParams, removeButton: removeButtonPros }));
    };
    // Вывод инпута для поиска услуг
    const renderSearchInput = () => (React.createElement(InputSearch, { value: searchValue, onChangeSearch: setSearchValue, testId: 'ServicesSearch', wrapperClassName: 'mt-4' }));
    const renderContent = () => (React.createElement(ServicesDashboardWrapper, { isLoading: isLoading, className: 'p-8' }, (servicesInCategoryData && availableServicesData) && (React.createElement(React.Fragment, null,
        React.createElement(ServicesDashboardAvailableItems, { availableItems: availableServices, setAvailableItems: setAvailableServices, title: '\u0421\u043F\u0440\u0430\u0432\u043E\u0447\u043D\u0438\u043A \u0443\u0441\u043B\u0443\u0433', renderButtons: renderChangeButtons, renderSearchInput: renderSearchInput, kind: availableServicesData.service_category.kind }),
        React.createElement(ServicesDashboardItemsInCategory, { itemsInCategory: servicesInCategory, setItemsInCategory: setServicesInCategory, title: `Услуги в категории «${servicesInCategoryData.service_category.name}»`, kind: servicesInCategoryData.service_category.kind, placeholder: '\u041D\u0435\u0442 \u0443\u0441\u043B\u0443\u0433 \u0432 \u044D\u0442\u043E\u0439 \u043A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u0438' })))));
    return renderContent();
};
export default SettingsServicesTabsServices;
